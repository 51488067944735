<template>
  <div class="recommend-plan">
    <div class="header">
      <div class="title-box">
        <p class="title">计划推介</p>
        <intro-default cont="每天推荐计划时间段，"></intro-default>
      </div>
      <div class="right">
        <div class="search-box">
          <p class="title">选择日期</p>
          <el-date-picker v-model="date" type="date" placeholder="选择日期" size="mini"></el-date-picker>
        </div>
        <div class="search-box">
          <el-select v-model="period" size="mini" placeholder="选择期数">
            <el-option v-for="item in periods" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="lz_cont">
      <div class="info">
        <span v-for="plan in data.plan_time" :key="plan.id">{{ plan.sort |
          timeSort }}<i>{{ plan.start_time }}-{{ plan.end_time }}</i></span>
      </div>
      <ul class="types">
        <li :class="{ active: i - 1 === planIndex }" v-for="i in planLength" :key="i" @click="planIndex = i - 1">{{ i |
          planLabel }}</li>
      </ul>
      <div class="table">
        <el-table :data="tableData" border style="width: 100%" header-cell-class-name="table-header-cell"
          cell-class-name="table-cell" row-class-name="table-row" :cell-style="tableCellStyle" size="small"
          row-key="id">
          <el-table-column label="开奖期数" align="center" prop="expect" width="128"></el-table-column>
          <el-table-column label="开奖结果" align="center" width="380">
            <template #default="scope">
              <div class="numbers open" v-if="scope.row.open.length">
                <template v-for="(number, i) in scope.row.open">
                  <number v-if="i === 0" class="number" :key="`${number}-${i}`" :value="number" :lottery-code="code"
                    :zhong="scope.row.winIndex >= 0"></number>
                  <number v-else class="number" :key="`${number}-${i}`" :value="number" :lottery-code="code"></number>
                </template>
              </div>
              <div v-else>等待开奖</div>
            </template>
          </el-table-column>
          <el-table-column :label="currentPlanName" align="center" width="225">
            <template #default="scope">
              <div class="numbers">
                <number class="number" v-for="(number, i) in scope.row.plan" :key="`${number}-${i}`" :value="number"
                  :lottery-code="code" :zhong="scope.row.winIndex === i"></number>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="当前成本" align="center" prop="current_cost"></el-table-column>
          <el-table-column label="累计成本" align="center" prop="sum_cost"></el-table-column>
          <el-table-column label="赢利/亏损" align="center" prop="money"></el-table-column>
          <el-table-column label="开奖时间" align="center" prop="open_time"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, DatePicker, Select, Option } from 'element-ui'
import moment from 'moment'
import NNumber from '@components/pc/Number'
const kChineseNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
export default {
  name: 'RecommendPlan',
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [NNumber.name]: NNumber
  },
  props: {
    code: String,
    id: String
  },
  filters: {
    timeSort(sort) {
      let label = '时间段'
      label += kChineseNum[sort]
      return label
    },
    planLabel(i) {
      let label = '计划'
      label += kChineseNum[i]
      return label
    }
  },
  data() {
    return {
      data: {
        list: [],
        plan_time: []
      },
      date: Date.now(),
      periods: [
        {
          label: '近10期',
          value: 10
        },
        {
          label: '近20期',
          value: 20
        },
        {
          label: '近30期',
          value: 30
        },
        {
          label: '近50期',
          value: 50
        },
        {
          label: '近100期',
          value: 100
        }
      ],
      period: 10,
      planIndex: 0
    }
  },
  computed: {
    planLength() {
      return this.data.list.length
    },
    tableData() {
      const arr = []
      if (this.data.list.length <= this.planIndex) return arr
      // eslint-disable-next-line camelcase
      for (const { id, expect, opencode, plan_opencode, current_cost, sum_cost, money, open_time } of this.data.list[this.planIndex]) {
        const open = opencode.split(',').filter(item => item !== '')
        const plan = plan_opencode.split(',').filter(item => item !== '')
        let winIndex

        const [firstCode] = open
        if (firstCode !== undefined) {
          winIndex = plan.indexOf(firstCode)
        } else {
          winIndex = -1
        }

        arr.push({
          id,
          expect: expect + '期',
          open,
          plan,
          current_cost,
          sum_cost,
          money,
          // eslint-disable-next-line camelcase
          open_time: moment(open_time * 1000).format('HH:mm:ss'),
          winIndex
        })
      }
      return arr
    },
    currentPlanName() {
      let label = '冠军购彩 计划'
      label += kChineseNum[this.planIndex + 1]
      return label
    }
  },
  watch: {
    code() {
      this.requestGetPlan()
    },
    date: function (val, oldVal) {
      this.requestGetPlan()
    },
    period: function (val, oldVal) {
      this.requestGetPlan()
    }
  },
  mounted() {
    this.requestGetPlan()
  },
  methods: {
    refresh() {
      this.requestGetPlan()
    },
    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'money') {
        if (row.money > 0) {
          return {
            color: '#fe1818',
            fontWeight: 600
          }
        } else if (row.money < 0) {
          return {
            color: '#19abe8',
            fontWeight: 600
          }
        } else {
          return null
        }
      } else {
        return null
      }
    },
    requestGetPlan() {
      this.$loading.show()
      this.$api.plan(this.code, moment(this.date).format('YYYY-MM-DD'), this.period).then(res => {
        this.planIndex = 0
        this.data = res.data
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        this.$message.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
@import "~@scss/pc/mixin";

.recommend-plan {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    .right {
      display: flex;
      align-items: center;
    }

    .search-box {
      display: flex;
      align-items: center;

      &+.search-box {
        margin-left: 15px;
      }

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .types {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;

    >li {
      cursor: pointer;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      color: #858586;
      background-color: #E3E3E5;
      width: 70px;
      height: 24px;
      border-radius: 4px;

      &.active {
        color: #FFFFFF;
        background-color: #FA3E3E;
      }

      &+li {
        margin-left: 10px;
      }
    }
  }

  >.info {
    margin-top: 20px;
    font-size: 14px;

    >span {
      i {
        font-style: normal;
        color: #ff0101;
        margin: 0 15px;

        &.tips {
          margin: 0;
        }
      }
    }
  }


  .table {

    .numbers {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 0;

      &.open {
        >.number+.number {
          opacity: .2;
        }
      }
    }
  }
}
</style>

<style>
/* .recommend-plan>.table {
  margin-top: 20px;
} */

.recommend-plan>.table .table-header-cell {
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  height: 40px;
}

.recommend-plan>.table .table-header-cell>.cell {
  padding: 0;
}

.recommend-plan>.table .table-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
  height: 33px;
}

.recommend-plan>.table .table-cell>.cell {
  padding: 0;
}

.recommend-plan>.table .table-row:hover>td,
.recommend-plan>.table .table-row .current-row>td {
  background-color: rgba(255, 204, 170, 0.4);
}
</style>